import {createSelector, createSlice} from "@reduxjs/toolkit";
import {sessionsActions} from "./sessions";
import {OFFLINE_MODE} from "@shared/utils/offlineModeUtilities";
import {getNextOrCurrentSession, getRegistrationMetadata} from "@utils/registrationsUtilities";
import dayjs from "@shared/services/dayjs";
import {projectsSelectors} from "./projects";
import {teamsSelectors} from "./teams";
import {createCustomEntityAdapter} from "@shared/utils/api/customEntityAdapter";
import {persistEntityInBackend} from "@shared/utils/api/persistEntityInBackend";
import {removeEntityInBackend} from "@shared/utils/api/removeEntityInBackend";
import {loadEntityFromBackend} from "@shared/utils/api/loadEntityFromBackend";
import {loadListFromBackend} from "@shared/utils/api/loadListFromBackend";
import {fetchWithMessages} from "@shared/utils/api/fetchWithMessages";
import {
  resetDependenciesContext,
  shouldAutoRefreshDependencies,
} from "@utils/features/featuresUtilities";
import {EntitiesSelectors, LoadListParams} from "@utils/features/types";
import {sorter} from "@shared/utils/sorters";

const registrationsAdapter = createCustomEntityAdapter({
  selectId: (el) => el._id,
  sortComparer: (a, b) => sorter.date(a.createdAt, b.createdAt),
});

export const registrationsSlice = createSlice({
  name: "registrations",
  initialState: registrationsAdapter.getInitialState({
    init: {status: "idle"},
    editing: {},
    current: undefined,
  }),
  reducers: {
    ...registrationsAdapter.reducers,
    setCurrent: (state, action) => {
      state.current = action.payload;
    },
    changeCurrent: (state, action) => {
      state.current = {...state.current, ...action.payload};
    },
  },
});

const asyncActions = {
  loadList:
    ({forceLoad, silent}: LoadListParams = {}) =>
    async (dispatch, getState) => {
      const state = getState();
      const projectId = state.currentProject.project._id;

      await loadListFromBackend(
        "registrations",
        projectId,
        state.registrations.init,
        () => dispatch(registrationsActions.initContext(projectId)),
        (data) => {
          dispatch(registrationsActions.initList({list: data, project: projectId}));
          // If there were some new changes (using forceLoad), also force update of dependencies
          shouldAutoRefreshDependencies(forceLoad, data) &&
            resetDependenciesContext(dispatch, sessionsActions);
        },
        forceLoad,
        !silent
      );
    },
  loadEditing: (entityId) => async (dispatch, getState) => {
    const state = getState();
    const projectId = state.currentProject.project._id;

    return loadEntityFromBackend(
      "registrations",
      entityId,
      projectId,
      state.registrations.editing,
      () => dispatch(registrationsActions.setEditing({_id: "new"})),
      (data) => dispatch(registrationsActions.setEditing(data)),
      {
        notFoundAction: () =>
          OFFLINE_MODE &&
          dispatch(registrationsActions.setEditing(state.registrations.entities[entityId])),
      }
    );
  },
  loadCurrent: (projectId) => async (dispatch) => {
    return loadEntityFromBackend("registrations", "current", projectId, undefined, null, (data) =>
      dispatch(registrationsActions.changeCurrent(data))
    );
  },
  persist: (fieldsToUpdate?: any) => async (dispatch, getState) => {
    const state = getState();
    const projectId = state.currentProject.project._id || fieldsToUpdate.project; // If no project id, fll back on the fields given
    const registrationState = state.registrations;

    // If some fields are given as argument, directly take this to update the registration
    const payload = fieldsToUpdate || registrationState.editing;

    return persistEntityInBackend(
      "registrations",
      {...payload, project: projectId},
      projectId,
      (data) => dispatch(registrationsActions.addToList(data)),
      (data) => {
        dispatch(registrationsActions.updateInList(data));
        // If the updated registration is the user's own registration, then update it as well
        if (data.user._id === registrationState.current.user._id) {
          dispatch(registrationsActions.changeCurrent(data));
        }
        resetDependenciesContext(dispatch, sessionsActions);
      }
    );
  },
  remove: (entityId) => async (dispatch, getState) => {
    const state = getState();
    const projectId = state.currentProject.project._id;

    await removeEntityInBackend(
      "registrations",
      entityId,
      projectId,
      registrationsSelectors.selectList(state),
      () => dispatch(registrationsActions.removeFromList(entityId))
    );
  },
  loadEditingHistory: (entityId) => async (dispatch, getState) => {
    const state = getState();
    const projectId = state.currentProject.project._id;

    !OFFLINE_MODE &&
      (await loadEntityFromBackend(
        "registrations",
        `${entityId}/history`,
        projectId,
        state.registrations.editing,
        null,
        (data) => dispatch(registrationsActions.changeEditing({history: data}))
      ));
  },
  getPdfPlanning: (id) => async (dispatch, getState) => {
    const state = getState();

    id = id || state.registrations.editing._id;
    try {
      return await fetchWithMessages(
        `projects/${state.currentProject.project._id}/pdf/registrationPlanning/${id}`,
        {isBlob: true, method: "GET"},
        {},
        false,
        true
      );
    } catch {
      return Promise.reject();
    }
  },
  unregister: (entityId?: string) => async (dispatch, getState) => {
    const state = getState();

    const currentProject = state.currentProject.project;
    const currentRegistrationId = entityId || state.registrations.editing._id;

    const updatedCurrentRegistration = await fetchWithMessages(
      `projects/${currentProject._id}/registrations/${currentRegistrationId}`,
      {
        method: "PATCH",
        body: {
          booked: false,
          availabilitySlots: [],
          formAnswers: {},
          sessionsSubscriptions: [],
          teamsSubscriptions: [],
          steward: null,
          helloAssoTickets: [],
          customTicketingTickets: [],
        },
      },
      {200: "Désinscription réussie"}
    );

    // Update in list
    await dispatch(registrationsActions.updateInList(updatedCurrentRegistration));
    await dispatch(registrationsActions.changeEditing(updatedCurrentRegistration));
  },
  hasNotShownUpInSession:
    (registrationId, sessionId, hasNotShownUp) => async (dispatch, getState) => {
      const state = getState();

      const currentProject = state.currentProject.project;

      const updatedCurrentRegistration = await fetchWithMessages(
        `projects/${currentProject._id}/registrations/${registrationId}/noShow/${sessionId}`,
        {
          method: "POST",
          body: {hasNotShownUp},
        },
        {200: "Modification réussie."}
      );

      // Update in list
      await dispatch(registrationsActions.updateInList(updatedCurrentRegistration));
    },
  sendInvitationEmail: (user, registrationAdditionalData) => async (dispatch, getState) => {
    const state = getState();

    try {
      await fetchWithMessages(
        `projects/${state.currentProject.project._id}/registrations/sendInvitationEmail`,
        {method: "POST", body: {user, ...registrationAdditionalData}},
        {200: "L'email d'invitation a bien été envoyé."},
        undefined,
        true
      );
      await dispatch(registrationsActions.loadList({forceLoad: true}));
    } catch {
      return Promise.reject();
    }
  },
};

const registrationsAdapterSelectors = registrationsAdapter.getSelectors(
  (state) => state.registrations
);

export const registrationsSelectors: EntitiesSelectors<any, any> = {
  selectEditing: (state) => state.registrations.editing,
  selectCurrent: (state) => state.registrations.current,
  selectList: registrationsAdapterSelectors.selectAll,
  selectById: registrationsAdapterSelectors.selectEntities,
  selectIsLoaded: (state) => state.registrations.init.status === "loaded",
};

registrationsSelectors.selectListWithMetadata = createSelector(
  [
    registrationsAdapterSelectors.selectAll,
    (state) => projectsSelectors.selectEditing(state).ticketingMode,
    (state) => projectsSelectors.selectEditing(state).formComponents,
    (state) => state.sessions.slotList,
    teamsSelectors.selectList,
  ],
  (registrations, ticketingMode, formComponents, sessionsSlotsList, teamsList) => {
    const project = {ticketingMode, formComponents};
    return registrations?.map((r) => ({
      ...r,
      ...getRegistrationMetadata(r, project),
      teamsSubscriptionsNames: r.teamsSubscriptions?.map((t) => t.name).join(", "),
      nextOrCurrentSession: getNextOrCurrentSession(r, sessionsSlotsList, teamsList, project),
      arrivalDateTime: [...(r.availabilitySlots || [])].sort(
        (a, b) => dayjs(a.start) - dayjs(b.start)
      )?.[0]?.start,
      departureDateTime: [...(r.availabilitySlots || [])].sort(
        (a, b) => dayjs(b.end) - dayjs(a.end)
      )?.[0]?.end,
    }));
  }
);

export const registrationsReducer = registrationsSlice.reducer;

export const registrationsActions = {
  ...registrationsSlice.actions,
  ...asyncActions,
};
